import moment from "moment"

// ======================> List <======================
/* eslint-disable */   // *** Disabled & fixed eslint rule : no-dupe-else-if 

export function limitDecimalNum(value, decimalNumToLimit = 2) {
  if (!value) return 0
  const moveDecimalPointNum = Math.pow(10, decimalNumToLimit)
  return Math.round(Number(value) * moveDecimalPointNum) / moveDecimalPointNum
}

export function splitArrayToChunk(array, chunkSize) {
  let result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk)
  }
  return result
}

// ======================> Validation <======================
export function validateEmail(email) {
  if (!email) return false
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function validateNum(val) {
  if (!val) return false
  return /^\d+$/.test(val)
}

// No special characters 
export function validateCharacter(val) {
  //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
  var regex = /^[A-Za-z0-9 ]+$/

  if (!val) return false
  return !regex.test(val)
}

// ======================> ETC <======================

// if navigator.clipboard not available
export function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function timeElapsed(date) {
  const now = moment()
  const timeStart = moment(date)
  return moment.duration(timeStart.diff(now)).humanize()
}

export function getRemainingTime(date, durationInSecond) {
  const endTime = moment(date).add(durationInSecond, "seconds")
  const now = moment()
  const remainingInMilliSec = endTime.diff(now)

  const remainingInSec = remainingInMilliSec/1000
  const isLessThan1Min = remainingInSec < 60
  const formatted = moment.utc(remainingInMilliSec).format("mm:ss")

  return { remainingInSec, isLessThan1Min, formatted }
}

export function toLowerCaseAndDash(text) {
  return String(text).toLowerCase().replaceAll(" ", "-")
}
export function toDash(text) {
  return String(text).replaceAll(" ", "-")
}
export function toUndashAndEncode(text) {
  return String(text).replaceAll("-", "%20")
}

export function escapeHtml(unsafe) {
  return unsafe.replaceAll('&', '&amp;')
              .replaceAll('<', '&lt;')
              .replaceAll('>', '&gt;')
              .replaceAll('"', '&quot;')
              .replaceAll("'", '&#039;');
}

export function removeAllHtmlTags(html) {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
}

export function checkUrl(string) {
  let givenURL
  try {
    givenURL = new URL (string);
  } catch (error) {
    return false; 
  }
  return true;
} 