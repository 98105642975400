<template>
  <div id="header">
    <!-- Mobile view -> first header + second-header + third header -->
    <!-- Web view -> second-header + third-header -->
    
    <div v-if="isMobile" id="first-header">
      <el-row type="flex" justify="end" align="middle" class="h-100">
        <el-col :span="isMobile ? 3 : 12">
          <el-tag v-if="env !== 'production'" size="mini" class="pointer">
            <template v-if="env === 'development' || env === 'test'">
              <span>DEV</span>
            </template>
            <template v-else-if="env === 'staging'">
              <span>STG</span>
            </template>
          </el-tag>
        </el-col>
        <el-col>
          <el-row type="flex" justify="end" align="middle" class="action-bar">

            <transition name="slide-left-bounce-down-leave-right">
              <el-tag 
                v-if="
                  isAuthenticated 
                  && (isFromPublic || isMobile) 
                  && latestTimeout 
                  && $route.fullPath !== '/bo/p2p-market?chosenTab=trade' 
                "
                class="latest-time-remaining-tag"
                :class="{ 'text-red': lessThan1Min }"
                @click="goTo('/bo/p2p-market?chosenTab=trade')"
                @auxclick="goTo('/bo/p2p-market?chosenTab=trade', true)"
              >
                <i class="custom-icon icon-header-clock mini"/>
                {{ latestTimeout }}
              </el-tag>
            </transition>

            <i class="custom-icon icon-faq mini pointer"/>

            <i
              v-if="isAuthenticated && (isFromPublic || isMobile)"
              class="custom-icon notification-public-header mini pointer"
              :class="haveUnread ? 'icon-route-notification-unread-black bounce-drop' : 'icon-route-notification'"
              @click="goTo('/bo/notification')"
              @auxclick="goTo('/bo/notification', true)"
            />

          </el-row>
        </el-col>
      </el-row>
    </div>
    <div id="second-header" :class="{ shadowed: isMobile }">
      <el-row type="flex" justify="space-between" align="middle" class="h-100">
        <template v-if="showSearchBar">
          <el-input
            size="mini"
            class="search-input"
            :placeholder="$t('SEARCH_NEWSILY')"
            v-model="searchInput"
            @keyup.enter.native="doSearch"
          />
          <el-button class="button bg-primary search-button with-icon" @click="doSearch()">
            <i class="custom-icon icon-header-search white-icon mini"></i>
          </el-button>
          <i class="el-icon-close grey-icon close-button" @click="showSearchBar = false"></i>
        </template>
        <template v-else>
          <div @click="goTo('/')" @auxclick="goTo('/', true)"><i class="custom-icon icon-logo pointer"/></div>
          <div v-if="!isMobile">
            <el-tag v-if="env !== 'production'" size="mini" class="pointer h-100">
              <template v-if="env === 'development' || env === 'test'">
                <span>DEVELOPMENT MODE ({{ roleName ? roleName : "guest" }})</span>
                <br/>
                <span>({{ user ? user.email : "" }})</span>
              </template>
              <template v-else-if="env === 'staging' || env === 'test'">
                <span>STAGING MODE ({{ roleName ? roleName : "guest" }})</span>
                <br/>
                <span>({{ user ? user.email : "" }})</span>
              </template>
            </el-tag>
          </div>

          <!-- MOBILE -->
          <div class="icon-wrapper" v-if="isMobile">
            <div>
              <el-row type="flex" align="middle" class="action-bar">
                <div class="icon-btn" v-show="isFromPublic && $route.path !== '/search' && !isFromBOAuth">
                  <i class="custom-icon icon-header-search black-icon small" @click="showSearchBar = true"></i>
                </div>
                <div class="icon-btn" v-show="!isAuthenticated && !isFromBOAuth" @click="goTo('/auth/sign-in')">
                  <i class="custom-icon icon-route-profile small"></i>
                </div>
                <div class="icon-btn favorite" v-show="isAuthenticated && !isFromBOAuth" @click="goTo('/favourite')">
                  <i class="custom-icon icon-favourite small"></i>
                </div>
                <div class="dashboard bg-black" v-show="isAuthenticated && !isFromBO" @click="goTo('/bo/dashboard')">
                  <i class="custom-icon icon-route-dashboard-outline"></i>
                </div>
                <div class="hamburger bg-primary" @click="menuOpen = true">
                  <i class="custom-icon icon-hamburger"></i>
                </div>
              </el-row>
            </div>
          </div>

          <div v-if="!isMobile" class="sh-right">
            <template v-if="!isFromBOAuth">
              <el-row type="flex" v-if="$route.path !== '/search' && !isFromBO">
                <el-input
                  size="mini"
                  class="search-input"
                  :placeholder="$t('SEARCH_NEWSILY')"
                  v-model="searchInput"
                  @keyup.enter.native="doSearch"
                />
                <el-button class="button black search-button with-icon" @click="doSearch()">
                  <i class="custom-icon icon-header-search mini"></i>
                </el-button>
              </el-row>
              <el-button 
                v-if="!isAuthenticated"
                class="button black btn-signup" 
                @click="goTo('/auth/sign-up')" 
                @auxclick.native="goTo('/auth/sign-up', true)"
              >
                {{ $t("SIGN_UP") }}
              </el-button>
              <template v-else>
                <el-button 
                  v-show="isAuthenticated && !isFromBO" 
                  class="button black with-icon icon-spaced dashboard" 
                  @click="goTo('/bo/dashboard')"
                >
                  <i class="custom-icon icon-route-dashboard mini"/>
                  {{ $t("DASHBOARD") }}
                </el-button>
                <el-button 
                  v-show="isAuthenticated && isFromBO" 
                  class="button black with-icon icon-spaced dashboard" 
                  @click="goTo('/')"
                >
                  <i class="custom-icon icon-home mini"/>
                  {{ $t("NEWS_PAGE") }}
                </el-button>
              </template>
            </template>
            <language-selection  />
            <div
              class="action-button pointer"
              @click="goTo('/faq')"
              @auxclick="goTo('/faq', true)"
            >
              <i class="custom-icon icon-faq mini"/>
              {{ $t("FAQ") }}
            </div>
            <el-button 
              v-if="!isAuthenticated && !isFromBOAuth"
              class="button white borderless with-icon icon-spaced btn-login" 
              @click="goTo('/auth/sign-in')" 
              @auxclick.native="goTo('/auth/sign-in', true)"
            >
              <i class="custom-icon icon-login mini"></i>{{ $t("LOG_IN") }}
            </el-button>
            <template v-if="isAuthenticated">
              <el-row type="flex" align="middle" justify="end">
                <div
                  class="action-button pointer"
                  :class="{ 'is-active': $route.fullPath == '/bo/notification' }"
                  @click="goTo('/bo/notification')"
                  @auxclick="goTo('/bo/notification', true)"
                >
                  <i class="custom-icon pointer mini grey-icon" :class="haveUnread ? 'icon-route-notification-unread bounce' : 'icon-route-notification'" />
                  {{ $t("NOTIFICATION") }}
                </div>
                <el-dropdown
                  @command="handleProfileCommand"
                  :hide-on-click="false"
                >
                  <span class="el-dropdown-link pointer action-button" :class="{ 'is-active': $route.fullPath == '/bo/profile' }">
                    <i class="custom-icon icon-login-profile pointer mini grey-icon" />
                    {{ $t("PROFILE") }}
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="profile">{{ $t("VIEW_PROFILE") }}<el-divider class="no-margin" /></el-dropdown-item>
                    <el-dropdown-item command="favourite">{{ $t("VIEW_FAVOURITE") }}<el-divider class="no-margin" /></el-dropdown-item>
                    <el-dropdown-item command="logout">{{ $t("LOG_OUT") }}</el-dropdown-item>
                  </el-dropdown-menu>

                </el-dropdown>

              </el-row>
              <transition name="slide-left-bounce-up-leave-right">
                <el-tag 
                  v-if="
                    latestTimeout 
                    && $route.fullPath !== '/bo/p2p-market?chosenTab=trade'
                  " 
                  class="latest-time-remaining-tag"
                  :class="{ 'text-red': lessThan1Min }"
                  @click="goTo('/bo/p2p-market?chosenTab=trade')"
                  @auxclick="goTo('/bo/p2p-market?chosenTab=trade', true)"
                >
                  <i class="custom-icon icon-header-clock mini"/>
                  {{ latestTimeout }}
                </el-tag>
              </transition>
            </template>
          </div>
        </template>
        
      </el-row>
    </div>
    <div id="third-header" v-if="!isFromBOAuth" class="shadowed">

      <!-- Public third header - showing categories -->
      <el-row type="flex" justify="space-between" align="middle">
        <el-col>
          <el-menu
            :default-active="activeTabId"
            class="nav-menu"
            mode="horizontal"
            menu-trigger="click"
          >
            <el-menu-item index="0" @click="goTo('/')">
              <i class="custom-icon icon-home mini white-icon" />
            </el-menu-item>

            <el-menu-item 
              v-for="el in allCategoryOutside" 
              :key="el.id" 
              :index="el.id.toString()"
              @click="goToByCategory(el.id)"
            >
              {{ el | translate(null,$route.query.locale) }}
            </el-menu-item>
            <el-submenu 
              v-if="allCategoryInside.length > 0"
              index="more"
              popper-class="popper-menu"
            >
              <template v-if="!isMobile" slot="title">{{ $t("MORE") }}</template>
              <el-row :type="isMobile ? '' : 'flex'" justify="start" class="w-100">
                <el-menu-item 
                  v-for="el in allCategoryInside" 
                  :key="el.id" 
                  :index="el.id.toString()"
                  @click="goToByCategory(el.id)"
                >
                  {{ el | translate(null,$route.query.locale) }}
                </el-menu-item>
              </el-row>
            </el-submenu>
          </el-menu>
        </el-col>
      </el-row>

    </div>


    <!-- Mobile only (menu) -->
    <el-dialog
      :visible.sync="menuOpen"
      class="menu-dialog"
      append-to-body
      fullscreen
    >
      <template slot="title">
        <i class="custom-icon icon-logo" @click="goTo('/')" @auxclick="goTo('/', true)" />
      </template>
      <language-selection isInMenu />
      <div class="menu-list" v-loading="menuList.length === 0">
        <div>
          <div 
            v-for="el in menuList" 
            :key="el.path"
            @click="goTo(el.path); menuOpen = false"
          > 
            <div class="mobile-menu-item" :class="{ highlight: isSelectedPath(el.path) }">
              <i class="custom-icon mini router-icon" :class="el.icon"/> {{ $t(el.nameCode) }}
            </div>
            <el-divider />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button 
          v-if="isAuthenticated" 
          v-loading="isLoggingOut" 
          class="button bg-transparent borderless with-icon" 
          @click="logout()"
        >
          <i class="custom-icon icon-logout small white-icon"/>{{ $t("LOG_OUT") }}
        </el-button>
        <span 
          v-else
          @click="goTo('/faq'); menuOpen=false"
        >
          {{ $t("ADVERTISE_WITH_US") }}
        </span>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin.js"
import { headerSidebarMixins } from "./header-sidebar-mixins"
import { getAllCategory } from "@/api/category.js"
import { mapGetters, mapState } from 'vuex';
import { EventBus } from "@/utils/event-bus.js"
import cssVariable from "@/assets/style/_variables.scss"
import debounce from "lodash/debounce"
import languageSelection from "../multi-language/language-selection.vue";

export default {
  name: "Header",
  mixins: [ generalMixin, headerSidebarMixins ],
  computed: {
    allCategoryOutside() {
      return this.allCategory.slice(0, this.categoryHeaderMaxNum)
    },
    allCategoryInside() {
      let result = this.allCategory.slice(this.categoryHeaderMaxNum, this.allCategory.length)
      this.$nextTick(() => this.checkAndRepairMenuOverload())
      return result
    },
    ...mapGetters(["roleName", "user"]),
    ...mapState('notification', ['haveUnread']),
    ...mapState('p2p-market', ['latestTimeout', 'lessThan1Min']),
  },
  components: {
    languageSelection
  },
  data() {
    return {
      env: process.env.VUE_APP_NODE_ENV,
      showSearchBar: false,
      searchInput: "",
      menuOpen: false,
      allCategory: [],
      categoryHeaderMaxNum: 0,
      isLoggingOut: false,
      prevHeaderWidth: 0,
    };
  },
  methods: {
    handleProfileCommand(type) {
      if(type === 'logout') this.logout()
      if(type === "favourite") this.goTo("/favourite")
      else this.goTo('/bo/profile')
    },
    isSelectedPath(path) {
      return path == this.$route.matched[1]?.path
    },

    getCategory() {
      const postData = {
        genericRecord: {
          status: "ACTIVE",
          type: "category"
        }
      }
      getAllCategory(postData).then(res => {
        if (res.result) {
          this.allCategory = res.result.list.sort((a, b) => a.sequences - b.sequences)
        }
      })
    },
    goToByCategory(id) {
      this.goTo(`/category/${id}`)
    },
    doSearch() {
      this.goTo({ path: "/search", query: { keyword: this.searchInput } })
      this.searchInput = ""
      this.showSearchBar = false
    },

    logout() {
      this.$confirm(this.$t("CONFIRM_LOGOUT"), this.$t("ARE_YOU_SURE"))
      .then(() => {
        this.isLoggingOut = true
        this.$store.dispatch('auth/logout')
        .then(() => {
          this.$notify({
            title: this.$t("SUCCESS"),
            message: this.$t("LOG_OUT_SUCCESS"),
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })
        })
        .finally(() => {
          this.isLoggingOut = false
          window.location.href = '/'
        })
      })
      .catch(() => {});
    },
    checkAndRepairMenuOverload: debounce(function() {
      var parents = document.querySelectorAll(".nav-menu");

      parents.forEach(parent => {
        const height = parent.clientHeight
        const width = parent.clientWidth
        const supposedHeight = Number(cssVariable.header3Height.replaceAll("px", ""))

        if (this.prevHeaderWidth < width && this.prevHeaderWidth !== 0) {
          // reset
          this.categoryHeaderMaxNum = this.isMobile ? 6 : 10
        }

        if (width !== 0) {
          this.prevHeaderWidth = width
        }
        
        if (height !== 0 && height > supposedHeight) {
          console.log("loop")
          if (this.categoryHeaderMaxNum > 1) this.categoryHeaderMaxNum--
        }
      })
    }, 100),
  },
  mounted() {
    this.getCategory()
    this.$nextTick(() => {
      this.categoryHeaderMaxNum = this.isMobile ? 6 : 10
      window.addEventListener('resize', this.checkAndRepairMenuOverload, true)
    })

    EventBus.$on("refresh-category", () => {
      this.getCategory()
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkAndRepairMenuOverload, true)
  }
};
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/general.scss";
@import "@/assets/style/mixin/animation.scss";
@include icon-colors;

@include bounce-for(2s);
@include bounce-drop-for(2s);

#header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0px 10px 15px #00000024;

  >div {
    >div {
      padding-left: $spacingLeftRight;
      padding-right: $spacingLeftRight;
      max-width: $viewMaxWidth;
      margin: auto;
    }
  }

  >#first-header {
    height: $header1Height;
    background-color: $secondary;
    color: $secondaryText;
    .action-bar >* {
      margin: 0.25rem 0.5rem;
    }
    .el-dropdown {
      .el-dropdown-link.el-dropdown-selfdefine {
        cursor: pointer;
        padding-right: 5px;
      }
    }
    i:not(.custom-icon) {
      height: 13px;
      width: 13px;
      background-size: 13px 13px;
      margin-right: 3px;
      margin-bottom: -2px;
    }
    .notification-public-header {
      margin-right: 15px;
    }
    .el-link:hover {
      i { @extend .primary-icon }
    }
  }

  >#second-header {
    height: $header2Height;
    background-color: white;
    color: $secondaryText;
    .el-button {
      margin: auto 0.5rem auto 0;
      font-family: $fontBaseBold;
    }
    .icon-logo {
      margin-top: 8px;
    }
    .dashboard:hover i { @extend .black-icon }
    .sh-right {
      display: flex;
      align-content: center;
    }

    .search-input {
      margin: auto;
      input {
        width: 100%;
        height: 2.2rem;
        background-color: $translucentBlack;
        border: 0;
        color: #ffffff;
      }
      input::-webkit-input-placeholder { /*Change the placeholder*/
        color: $white;
      }
    }
    .search-button {
      position: relative;
      left: -0.5rem;
      top: 0;
      margin: 0;
      z-index: 2;
      height: calc(2.2rem + 3px);
      border-radius: 10%;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      background-color: $black;
    }
    .action-button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      margin-left: 10px;
      font-family: inherit;
      font-size: 14px;
      color: $secondaryText;

      i {
        margin-right: 5px;
      }

      &:hover,
      .is-active,
      &.is-active {
        .custom-icon { @extend .primary-icon }
        background-color: $white;
        i { @extend .primary-icon }
        color: $primary;
      }
    }
  }

  >#third-header {
    height: $header3Height;
    background-color: $primary;
    color: $primaryText;
    box-shadow: 1px 6px 10px 0px rgb(0 0 0 / 15%);
    .el-menu.el-menu--horizontal {
      border: 0;
    }
    .nav-menu {
      background-color: $primary;
      .el-menu-item, 
      .el-submenu__title,
      .el-submenu__icon-arrow,
      .el-menu-item.is-active {
        color: $white;
        font-weight: bold;
        &:hover, &.is-active {
          background-color: $white;
          border: 0;
          &, .el-submenu__icon-arrow {
            color: $primary;
          }
        }
      }
      .el-menu-item {
        &:first-child { //icon-home
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        } 
        &:hover, &.is-active {
          background-color: $white;
          i { 
            @extend .primary-icon;
            &.icon-home { @extend .primary-icon }
          }
        }
      }
    }
    .router-icon {
      margin-right: 5px;
      margin-bottom: 5px;
    }
    .el-button {      
      width: calc($header2Height - 1.5rem);
      height: calc($header2Height - 1.5rem);
    }    
  }

  .latest-time-remaining-tag {
    border-radius: 20px;
    background-color: $white;
    font-weight: bold;
    font-size: 18px;
    color: #FFB127;
    cursor: pointer;
    margin-left: 7px;
    margin-top: 8px;
    display: inline-flex;
    align-items: center;
    &.text-red {
      .icon-header-clock {
        @extend .red-icon;
      }
    }
  }
  #first-header .latest-time-remaining-tag {
    font-size: 14px;
    .custom-icon {
      height: 15px;
      width: 15px;
      background-size: 15px 15px;
    }
  }

  .btn-login, .btn-signup {
    min-width: 110px;
    margin-left: 10px !important;
  }

}

.menu-dialog {
  .el-dialog__header {
    background-color: $bgColor;
    height: 10vh;
    .el-dialog__headerbtn {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: $white;
      top: 15px;
    }
  }
  .el-dialog__body {
    background-color: $primary;
    height: 80vh;
    color: $primaryText;
    margin: 0;
    .el-divider {
      margin: 5px 0;
    }
    .menu-list {
      overflow: scroll;
      height: 100%;
      .mobile-menu-item {
        border-radius: 5px;
        padding-left: 0.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        i { 
          @extend .white-icon;
          vertical-align: middle;
        }
        &.highlight { 
          background-color: $white;
          color: $primary;
          i { @extend .primary-icon }
        }
      }
    }
  }
  .el-dialog__footer {
    background-color: $primary;
    height: 10vh;
    color: $primaryText;
    text-align: center;
  }
  .icon-logo {
    height: 37.3px;
    width: 139px;
  }
  .dialog-footer {
    .el-button {
      span {
        color: $white
      }
    }
  }
}


// mobile view
.mobile {
  #header {
    box-shadow: 0px 10px 15px rgb(0 0 0 / 4%);
    >div {
      >div {
        padding-left: $spacingLeftRightMobile;
        padding-right: $spacingLeftRightMobile;
      }
    }
    >#first-header {
      .el-row >* {
        margin: auto 0.2rem;
      }
      .notification-public-header,
      .icon-faq,
      .latest-time-remaining-tag {
        margin-right: 10px;
      }
    }

    >#second-header {
      height: $header2HeightMobile;
      .icon-wrapper {
        .action-bar {
          position: absolute;
          right: -$spacingLeftRightMobile;
          top: 0;
          text-align: center;
          i {
            height: $header2HeightMobile;
            width: calc($header2HeightMobile - 10px);
          }
          .hamburger,
          .dashboard {
            height: $header2HeightMobile;
            margin-bottom: 4px;
            i { 
              width: $header2HeightMobile !important;
              background-size: contain
            }
          }
          .hamburger {
            margin-right: 5px;
          }
          .dashboard i { 
            background-size: 35px
          }
          .el-button {
            height: auto;
          }
        }
      }
      .search-input {
        margin: auto;
        width: 100%;
        input {
          width: calc(100% + 10px);
          height: calc($header2HeightMobile - 1rem);
        }
      }
      .search-button {
        height: 46px;
        width: 46px;
        left: 6px;
      }
      .close-button {
        margin: auto 0 auto 0.5rem;
        font-size: 1.5rem;
      }
      .icon-logo {
        height: 37.3px;
        width: 139px;
      }
      .icon-btn {
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;
        &.favorite {
          background-color: #F0F0F0;
        }
      }
      .icon-favourite {
        color: black;
      }
    }

    #third-header {
      height: $header3HeightMobile;
      .el-submenu {
        float: right;
        .el-submenu__icon-arrow {
          margin: 0;
        }
      }
      .el-menu-item,
      .el-submenu__title {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
      }
    }
  }
}


</style>